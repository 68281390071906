<!--
 * @Descripttion: 
 * @version: 1.0.0
 * @Author: Aggie
 * @Date: 2023-11-28 11:06:08
-->
<template>
  <div>
    <Popup v-model="isBtnShowPopup" position="bottom" :style="{ height: '20%' }" closeable>
      <div class="btnGroupBtn">
        <div v-for="(item, index) in btnList" :key="index" @click="btnClick(item.callback)">
          <svg class="icon" aria-hidden="true">
            <use :xlink:href="'#' + item.icon" />
          </svg>
          <p >{{ item.txt }}</p>
        </div>
      </div>
    </Popup>
  </div>
</template>

<script>
import { Popup } from 'vant';
import 'vant/lib/index.css';
export default {
  name: 'btnGroup',
  props: ['btnList'],
  components: {
    Popup
  },
  data() {
    return {
      isBtnShowPopup: false
    };
  },
  methods: {
    showPopup() {
      this.isBtnShowPopup = true;
    },
    btnClick(type){
        this.$parent[type]();
        console.log(' this.$parent[type]', this.$parent[type])
        this.isBtnShowPopup = false;
    }
  }
};
</script>

<style>
.btnGroup {
  padding: 0 0.2rem 0.3rem 0.2rem;
}

.btnGroupBtn {
  background: #fff !important;
  color: #13161b !important;
  font-size: 0.17rem;
  /* height: 0.35rem; */
  /* line-height: 0.35rem; */
  padding: 0.05rem 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
}
</style>
