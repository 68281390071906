<template>
  <div class="editOrderBox">
    <iHeader
      backType="blank"
      :headStyle="{
        background: 'var(--themeColor)',
        color: '#fff'
      }"
      :backEvent="true"
      @back-event="cityEditOrderback()"
      :back="true"
    >
      <div slot="headText">
        <span class="head-txt">{{ ['pfizer'].includes($cookies.get('tenant')) ? '租车需求' : '城市车队需求' }}</span>
      </div>
    </iHeader>
    <div class="editOrderContent" ref="editOrderScroll">
      <div class="editBox">
        <div class="publicTips" @click="openTipsPage()">
          <div>
            注意事项 :
            {{ ['pfizer'].includes(this.$cookies.get('tenant')) ? '租车需求' : '地方车队需求' + '，请提前2天预定...' }}
          </div>
          <div>
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#iconright" />
            </svg>
          </div>
        </div>
        <div class="normalInputRow" @click="choiceCity()">
          <MInput disabled="true" noCancel="false" title="用车城市" v-model="formdata.city" pla="请选择用车城市" must="true"></MInput>
        </div>
        <div class="normalInputRow" @click="openComponent('carstyle')">
          <MInput disabled="true" noCancel="false" title="用车形式" v-model="formdata.carModus" pla="请选择用车形式" must="true"></MInput>
        </div>
        <div class="fitBox" v-if="isShowZoneinfo">
          <a @click="selectArea(item)" :class="item.isselec && 'selectFit'" v-for="(item, index) in lesserAreas" :key="index">{{ item.lesserArea }}</a>
        </div>
        <template v-if="!formdata.carModus.includes('包车')">
          <div class="normalInputRow departure" @click="goMap('departure')">
            <div class="departure_label">
              <i class="greenBc"></i>
              <span>出发地</span>
              <span class="must">*</span>
            </div>
            <input type="text" v-model="formdata.itemData.departure" class="departure_inner" placeholder="请选择出发地" disabled="true" />
          </div>
          <div class="normalInputRow departure" @click="goMap('destination')">
            <div class="departure_label">
              <i class="orangeBc"></i>
              <span>目的地</span>
              <span class="must">*</span>
            </div>
            <input type="text" v-model="formdata.itemData.destination" class="departure_inner" placeholder="请选择目的地" disabled="true" />
          </div>
        </template>
        <div class="normalInputRow" @click="openComponent('useDate')">
          <MInput title="用车时间" noCancel="false" disabled="true" v-model="formdata.dtStart" pla="请选择用车时间" must="true"></MInput>
        </div>

        <div class="normalInputRow" @click="openComponent('cartype')">
          <MInput disabled="true" noCancel="false" title="车型" v-model="formdata.carType" pla="请选择车型" must="true"></MInput>
        </div>
        <div class="contact_box">
          <div class="passengerBox" v-if="['novartis'].includes($cookies.get('tenant'))">
            <div class="normalInputRow normalInputRowHalf">
              <MInput title="乘车联系人" noCancel="false" :must="true" v-model="formdata.itemData.passenger.name" pla="请输入乘车人姓名"
              :disabled="['novartis'].includes($cookies.get('tenant'))"
              ></MInput>
            </div>
          </div>
          <div class="hcpBox bighcpBox" @click="showPhone()" v-if="['novartis'].includes($cookies.get('tenant'))">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#iconrenshishuju" />
            </svg>
            参会人列表
          </div>
        </div>
        <div class="normalInputRow departure" v-if="['novartis'].includes($cookies.get('tenant'))">
          <div class="departure_label">
            <span>乘车联系人电话</span>
            <span class="must">*</span>
          </div>
          <input type="number" pattern="[0-9]*" v-model="formdata.itemData.passenger_phone" class="departure_inner" placeholder="请输入乘车人电话"
          :disabled="['novartis'].includes($cookies.get('tenant'))"
          />
        </div>
        <!-- 山德士不需要用车数量 -->
        <div class="normalInputRow departure" v-if="!['novartis'].includes($cookies.get('tenant'))">
          <div class="departure_label">
            <span>数量</span>
            <span class="must">*</span>
          </div>
          <input type="number" pattern="[0-9]*" v-model="formdata.qty" class="departure_inner" placeholder="请输入数量" />
        </div>

        <div class="normalInputRow">
          <MInput title="备注" noCancel="false" v-model="formdata.descr" pla="请输入备注"></MInput>
        </div>
        <div v-if="['novartis'].includes($cookies.get('tenant')) && showFile" class="uploader_row">
          <div class="departure_label">
            <span>跨城用车审批图片:</span>
            <span class="must">*</span>
          </div>
          <viewer :images="approveFilesList" class="imgContainer">
            <template v-for="list in approveFilesList">
              <div :key="list.url">
                <img :src="list.url" alt />
                <Svgs color="#FF484D" class="paymentDelete" name="icon-guanbi3" @click.native="deleteInvoice(list, 'invoice')"></Svgs>
              </div>
            </template>
            <div class="uploadImg" @click="openBtnGroup('invoice')">
              <Svgs color="#FFFFFF" name="icon-tianjia3"></Svgs>
            </div>
          </viewer>
        </div>
      </div>
      <div class="normalInputRow isSpecialBox">
        <div class="specialMain" @click="selectNeedtype()">
          <p>
            {{ ['pfizer'].includes($cookies.get('tenant')) ? '跨城用车' : '特殊需求/跨城用车' }}
          </p>
          <span :class="['specialBtn', formdata.isSpecial == 0 ? 'offBtn' : 'onBtn']">
            <svg class="icon" aria-hidden="true">
              <use :xlink:href="formdata.isSpecial == 0 ? '#iconoff' : '#iconon'" />
            </svg>
          </span>
        </div>
        <div class="specialAdress" v-if="formdata.isSpecial != 0 && formdata.carModus.includes('包车')">
          <div class=" departure" @click="goMap('departure')">
            <div class="departure_label">
              <i class="greenBc"></i>
              <span>出发地</span>
            </div>
            <input type="text" v-model="formdata.itemData.departure" class="departure_inner" placeholder="请选择出发地" :disabled="true" />
          </div>
          <div class=" departure" @click="goMap('destination')">
            <div class="departure_label">
              <i class="orangeBc"></i>
              <span>目的地</span>
            </div>
            <input type="text" v-model="formdata.itemData.destination" class="departure_inner" placeholder="请选择目的地" :disabled="true" />
          </div>
        </div>
      </div>
      <div class="supplerBox">
        <levelLists @choseLevel="choseLevel" :levelList="supplierList" :goDetail="goDetail" :isloding="isloding" :formdata="formdata"></levelLists>
      </div>
    </div>
    <div class="bottom_btn" v-show="hidshow">
      <div :class="['saveBtn', isloding && 'disableBtn']" @click="!isloding && saveItem()">
        保存
      </div>
    </div>

    <Map ref="mapPop" @close="initForm" :oldCity="formdata.city"></Map>
    <Select val="name" :clickClose="true" :title="selecttTtle" @on-ok="chooseSelect" :selectList="selectList" ref="selectRef"></Select>
    <BtnGroup ref="btnGroup" :btnList="btnList" />
    <SearchCityCar @on-ok="chooseCity" :cityList="cityList" ref="eventCity"></SearchCityCar>
    <!-- <Calendar
      :color="'var(--themeColor)'"
      v-model="showDataPicker"
      @confirm="chooseSelect"
    /> -->
    <Popup v-model="showDataPicker" position="bottom" :style="{ height: '38%' }">
      <DatetimePicker
        ref="datePicker"
        :formatter="formatter"
        type="datetime"
        :filter="filter"
        title="选择用车时间"
        :min-date="startDate"
        @confirm="chooseSelect"
        @cancel="showDataPicker = false"
      ></DatetimePicker>
    </Popup>
    <supplierDetail ref="supplierDetail" :choseLevel="choseLevel"></supplierDetail>
    <chosePhonePerson ref="chosePhonePerson" @chosePPerson="chosePPerson"></chosePhonePerson>
    <tipsPage ref="tipsPage"></tipsPage>
    <div id="containerP"></div>
  </div>
</template>

<script>
import filterEmoji from '@/views/controllers/filterEmoji.js';
import SearchCityCar from '@/views/common/SearchCityCar/SearchCityCar';
import tipsPage from '@/views/common/tenant/tipsPage.vue';
import supplierDetail from '@/views/common/tenant/supplierDetail.vue';
import Map from '@/views/common/map/map';
import levelLists from '@/views/common/tenant/levelLists.vue';
import vueFilter from '@/views/controllers/filter/vuefilter.js';
import screenMixin from '@/views/controllers/getScreen.js';
import { additem as validator } from '@/views/controllers/carValidatorConfig';
// import { Calendar } from "vant";
import { DatetimePicker, Popup } from 'vant';
import 'vant/lib/index.css';
import { UploadPlug } from 'smart-filer';
import BtnGroup from '@/views/common/btnGroup.vue';
import { Dialog } from 'vant';
import chosePhonePerson from '@/views/common/chosePhonePerson';
// import uploader from '@/icomponents/uploader.vue';
const uploadInstance = new UploadPlug({
  host: process.env.VUE_APP_GATEWAY
});
export default {
  mixins: [filterEmoji, screenMixin],
  data() {
    return {
      showDataPicker: false,
      endDate: '',
      startDate: '',
      cityList: [],
      choseLevelType: {},
      isloding: true,
      cartypes: [],
      carstyles: [],
      supplierList: [],
      isShowZoneinfo: false,
      lesserAreas: [],
      selectList: [],
      selecttTtle: '',
      selectOpen: '',
      formdata: {
        isExtra: false,
        itemId: '',
        proposalId: this.SmartStorage.get('proposalId'),
        isSpecial: 0,
        qty: 1,
        descr: '',
        city: '',
        carModus: '',
        carType: '',
        dtStart: '',
        // province: "上海市",
        itemData: {
          startLngLat: [],
          endLngLat: [],
          departure: '',
          destination: '',
          isSpecial: false,
          lesserAreas: {},
          passenger: {
            name: ''
          },
          passenger_phone: ''
        }
      },
      approveFilesList: [],
      btnList: [
        {
          txt: '拍照',
          callback: 'graph',
          icon: 'icon-paizhao'
        },
        {
          txt: '从相册选取',
          callback: 'album',
          icon: 'icon-tupian'
        }
      ],
      showFile: false, //山德士跨城用车审批图片展示
      editFormdata: false //城市车队编辑订单
    };
  },
  components: {
    supplierDetail,
    levelLists,
    Map,
    tipsPage,
    Popup,
    DatetimePicker,
    BtnGroup,
    SearchCityCar,
    chosePhonePerson
  },
  created() {
    this.showFile = false;
    this.setRouteListeners('cityEditOrderback');
    this.$root.$eventHub.$on('cityEditOrderback', data => {
      this.cityEditOrderback();
      this.$root.$eventHub.$off('cityEditOrderback'); //解绑当前监听，否则下轮监听还会持续叠加
    });
    this.getcarstyles();
    this.getcartypes();
    this.getCity();
  },
  mounted() {
    if (this.SmartStorage.get('carItem')) {
      console.log('编辑订单==', this.formdata);
      this.editFormdata = true; //城市车队编辑订单
      this.formdata = this.SmartStorage.get('carItem');
      this.formdata.descr = this.StringExchangeEmoji(this.formdata.descr);
      //山德士跨城用车审批邮件显示逻辑
      if (['novartis'].includes(this.$cookies.get('tenant'))) {
       
        if (this.formdata.itemData.puCity && this.formdata.itemData.destCity) {
          if (this.formdata.itemData.destCity != this.formdata.itemData.puCity) {
            this.showFile = true;
            console.log('this.formdata.itemId=====', this.formdata.itemId);
            this.getInvoiceFiles(this.formdata.itemId);
          } else {
            this.showFile = false;
          }
        }
      }
    } else {
      console.log('新建订单==');
      if (['novartis'].includes(this.$cookies.get('tenant'))) {
        this.formdata.city=this.SmartStorage.get('eventData').city;
        this.formdata.province=this.SmartStorage.get('eventData').province;
      }
      this.ItemId = this.generateUUID();
    }
    let now = new Date();
    this.startDate = new Date(now.getTime() + 1000 * 60 * 30);
    console.log('this.formdata', this.formdata);
  },
  watch: {
    'formdata.city': {
      handler(newValue, oldValue) {
        this.changePrice();
      }
    },
    'formdata.carModus': {
      handler(newValue, oldValue) {
        this.changePrice();
      }
    },
    'formdata.carType': {
      handler(newValue, oldValue) {
        this.changePrice();
      }
    },
    'formdata.itemData.departure': {
      handler(newValue, oldValue) {
        this.changePrice();
      }
    },
    'formdata.itemData.destination': {
      handler(newValue, oldValue) {
        this.changePrice();
      }
    },
    'formdata.itemData.lesserAreas.lesserArea': {
      handler(newValue, oldValue) {
        this.changePrice();
      }
    }
  },
  methods: {
    filter(type, options) {
      if (type === 'minute') {
        return options.filter(option => option % 15 === 0);
      }
      return options;
    },
    formatter(type, val) {
      if (type === 'year') {
        return `${val}`;
      } else if (type === 'month') {
        return `${val}`;
      }
      return val;
    },
    changePrice() {
      if (
        this.formdata.city &&
        this.formdata.carModus &&
        this.formdata.carType &&
        (this.formdata.carModus.includes('包车') ? true : this.formdata.itemData.departure && this.formdata.itemData.destination)
      ) {
        this.GetQuoteds();
      }
    },
    openTipsPage() {
      this.$refs.tipsPage.openPop();
    },
    getCity() {
      let params = {
        keyCode: 'CPC-City'
      };
      let _this = this;
      this.services.queryDict(params).then(res => {
        if (res.success) {
          _this.cityList = res.content;
        }
      });
    },
    choseLevel(data) {
      this.choseLevelType = data;
      console.log(data);
    },
    cityEditOrderback() {
      this.removeRouteListeners('cityEditOrderback');
      this.$router.push('/items');
    },
    goDetail(data) {
      console.log(data);
      let params = {
        supplierId: data.supplierId,
        city: this.formdata.city
      };
      this.$refs.supplierDetail.showPopup(data, params);
    },
    checkTime(datatime) {
      let data = this.SmartStorage.get('checkCanNewItem');
      if (
        new Date(datatime.replace(/-/g, '/').substr(0, 10)) > new Date(data.useEndDate.replace(/-/g, '/').substr(0, 10)) ||
        new Date(datatime.replace(/-/g, '/').substr(0, 10)) < new Date(data.useStartDate.replace(/-/g, '/').substr(0, 10))
      ) {
        return true;
      }
      return false;
    },
    async saveItem() {
      if (this.formdata.dtStart && new Date() >= new Date(this.formdata.dtStart.replace(/-/g, '/'))) {
        this.$iToast('请选择当前时间之后的时间');
        return false;
      }
      if (this.SmartStorage.get('checkCanNewItem').useStartDate && this.checkTime(this.formdata.dtStart)) {
        this.$iMessageBox.render({
          btnType: 'single',
          singleTxt: '确定',
          messageTitle: '提示',
          messageTxt: '用车时间超出可用时间范围：' + this.SmartStorage.get('checkCanNewItem').useStartDate + '~' + this.SmartStorage.get('checkCanNewItem').useEndDate + '，不可用车'
        });
        return false;
      }
      /**
       * 山德士下单用车卡控逻辑
       */

      //山德士跨城市用车逻辑
      
      if (['novartis'].includes(this.$cookies.get('tenant'))) {
        const eventData = this.SmartStorage.get('eventData');
        if (eventData.extData.isLargeMeeting) {
          if (this.showFile) {
            if (this.approveFilesList.length == 0) {
              this.$iToast('跨城用车请先上传审批图片');
              return false;
            } else {
              if (this.editFormdata) {
                this.formdata.itemId = this.SmartStorage.get('carItem').itemId;
              } else {
                this.formdata.itemId = this.ItemId;
              }
              this.formdata.itemData.differentCity = 1;
            }
          } else {
            this.formdata.itemData.differentCity = 0;
          }
        } else {
          // 小会跨城用车逻辑
          if (this.showFile) {
            this.formdata.itemData.differentCity = 1;
          } else {
            this.formdata.itemData.differentCity = 0;
          }
        }

        if (eventData.extData.isLargeMeeting) {
          let isTrafficHub = /^((?!火车站|飞机场|高铁站).)*$/;
          //  大会 出发地和目的地都不是会议城市
          if (this.formdata.itemData.destCity != eventData.city && this.formdata.itemData.puCity != eventData.city) {
            console.log('非会议城市==');
            this.formdata.itemData.isMeetingCity = 0;
            if (!isTrafficHub.test(this.formdata.itemData.start_type) || !isTrafficHub.test(this.formdata.itemData.end_type)) {
              this.formdata.itemData.isTrafficHub = 1;
            } else {
              this.$iToast('大会用车必须在会议城市或者必须有一个非会议城市的交通枢纽');
              this.formdata.itemData.isTrafficHub = 0;
              return false;
            }
          } else {
            console.log('会议城市==');
            if (!isTrafficHub.test(this.formdata.itemData.start_type) || !isTrafficHub.test(this.formdata.itemData.end_type)) {
              this.formdata.itemData.isTrafficHub = 1;
            } else {
              this.formdata.itemData.isTrafficHub = 0;
            }
            this.formdata.itemData.isMeetingCity = 1;
          }
        } else {
          console.log('小会==', this.formdata);
          this.formdata.itemData.isTrafficHub = 0;             
          if (this.formdata.itemData.destCity != eventData.city || this.formdata.itemData.puCity != this.formdata.city) {
            console.log('小会非会议城市==');
            this.formdata.itemData.isMeetingCity = 0;
            this.$iToast('小会不允许跨城用车');
            return false;
          } else {
            this.formdata.itemData.isMeetingCity = 1;
            console.log('小会会议城市==');
          }
        }
      }
      console.log('跨城保存this.formdata==', this.formdata);

      this.formdata.descr = this.EmojiExchangeString(this.formdata.descr);
      this.isloding = true;
      this.formdata.supplierId = this.choseLevelType.supplierId;
      this.formdata.supplierName = this.choseLevelType.supplierName;
      this.formdata.itemData.unitPrice = this.choseLevelType.unitPrice;
      this.formdata.itemData.supplierThumbnail = this.choseLevelType.supplierLog;
      if (this.formdata.isSpecial == '1') {
        this.formdata.amount = null;
        this.formdata.price = null;
      } else {
        this.formdata.price = this.choseLevelType.price;
        this.formdata.amount = this.choseLevelType.price * this.formdata.qty;
      }
      var validateResult = this.validate(this.formdata, validator);
      if (!validateResult) {
        this.isloding = false;
        return;
      }
      console.log('formdata==', this.formdata);
      this.$iDelay(() => {
            this.services.SaveItem(this.formdata).then(res => {
              if (res.success) {
                this.isloding = false;
                this.$iToast('保存成功');
                this.cityEditOrderback();
              } else {
                this.$iToast(res.msg);
                this.isloding = false;
              }
            });
          }, 2000);
    },
    //更新表单信息
    async initForm(type) {
      console.log('initForm===');
      console.log('this.$store.state.extdata', this.$store.state.extdata);
      if (this.type == 'departure') {
        this.formdata.itemData.departure = this.$store.state.extdata.addressName;
        this.formdata.puCity = this.$store.state.extdata.city;
        this.formdata.itemData.puCity = this.$store.state.extdata.city;
        this.formdata.itemData.start_type = this.$store.state.extdata.type;
        // this.isshowRequirementinfo = true;
        this.formdata.itemData.startLngLat = [this.$store.state.extdata.longitude, this.$store.state.extdata.latitude];
        if (this.formdata.itemData.destination && this.formdata.itemData.departure) {
          await this.getDistance();
        }
      }
      if (this.type == 'destination') {
        this.formdata.itemData.destination = this.$store.state.extdata.addressName;
        this.formdata.destCity = this.$store.state.extdata.city;
        this.formdata.itemData.destCity = this.$store.state.extdata.city;
        this.formdata.itemData.end_type = this.$store.state.extdata.type;
        this.formdata.itemData.endLngLat = [this.$store.state.extdata.longitude, this.$store.state.extdata.latitude];
        if (this.formdata.itemData.destination && this.formdata.itemData.departure) {
          await this.getDistance();
        }
        // this.isshowRequirementinfo = true;
      }
      if (this.type == 2) {
        this.formdata.itemData.tripList[this.tripIndex].departure = this.$store.state.extdata.addressName;
      }
      if (this.type == 1) {
        this.formdata.itemData.tripList[this.tripIndex].destination = this.$store.state.extdata.addressName;
      }
      this.$forceUpdate();
      console.log('formdata====', this.formdata);

      //山德士跨城用车审批邮件显示逻辑
      const eventData = this.SmartStorage.get('eventData');
      if (['novartis'].includes(this.$cookies.get('tenant'))) {
        if (this.formdata.itemData.puCity && this.formdata.itemData.destCity&&eventData.extData.isLargeMeeting) {
          if (this.formdata.itemData.destCity != this.formdata.itemData.puCity) {
            this.showFile = true;
            // console.log('this.formdata.itemId=====', this.formdata.itemId);
            // this.getInvoiceFiles(this.formdata.itemId);
          } else {
            this.showFile = false;
          }
        }
      }
    },
    goMap(type, index) {
      this.type = type;
      this.tripIndex = index;
      this.$refs.mapPop.open(
        type == 'departure'
          ? {
              longitude: this.formdata.itemData.startLngLat && this.formdata.itemData.startLngLat[0],
              latitude: this.formdata.itemData.startLngLat && this.formdata.itemData.startLngLat[1],
              name: this.formdata.itemData.departure,
              city: this.formdata.city
            }
          : {
              longitude: this.formdata.itemData.endLngLat && this.formdata.itemData.endLngLat[0],
              latitude: this.formdata.itemData.endLngLat && this.formdata.itemData.endLngLat[1],
              name: this.formdata.itemData.destination,
              city: this.formdata.city
            }
      );
    },
    choiceCity() {
      this.$refs.eventCity.openPop();
    },
    selectNeedtype() {
      console.log(this.formdata.isSpecial);
      // if(this.formdata.carModus.includes('包车')){
      this.formdata.isSpecial = this.formdata.isSpecial == 0 ? 1 : 0;
      this.formdata.itemData.isSpecial = !this.formdata.itemData.isSpecial;
      //   }
    },
    async getcarstyles() {
      this.services.getcarstyles({}).then(res => {
        if (res.success) {
          this.carstyles =
            res.content &&
            res.content.map(v => {
              return { ...v, txt: v.name };
            });
        }
      });
    },
    async getzoneinfo() {
      let params = {
        City: this.formdata.city || '上海市',
        Province: this.formdata.province || '上海市',
        LesserType: 1
      };
      this.services.getzoneinfo(params).then(res => {
        if (res.success) {
          this.lesserAreas = res.content.lesserAreas.map(v => {
            return {
              ...v,
              isselec: this.formdata.itemData.lesserAreas.zoneId == v.zoneId ? true : false
            };
          });
          console.log(this.lesserAreas);
        }
      });
    },
    selectArea(item) {
      this.lesserAreas.forEach(element => {
        element.isselec = element.lesserArea != item.lesserArea ? false : true;
      });
      this.formdata.itemData.lesserAreas = item;
      this.GetQuoteds();
    },
    async getcartypes() {
      this.services.getcartypes({}).then(res => {
        if (res.success) {
          this.cartypes =
            res.content &&
            res.content.map(v => {
              return { ...v, txt: v.name };
            });
        }
      });
    },
    chooseCity(data) {
      console.log(data);
      if (data) {
        this.$set(this.formdata, 'city', data.val);
        this.$set(this.formdata, 'province', data.lnkVal);
        this.formdata.itemData.lesserAreas = {};
        this.isShowZoneinfo = false;
        this.formdata.itemData.styleId = null;
        this.formdata.carModus = '';
        this.supplierList = [];
      }
    },
    chooseSelect(e) {
      console.log(e);
      let datatime = vueFilter.formatDate(e, 'yyyy-MM-dd hh:mm');
      switch (this.selectOpen) {
        case 'carstyle':
          this.formdata.carModus = e.name;

          if (this.formdata.carModus.includes('包车')) {
            this.formdata.itemData.mileage = null;
            this.formdata.itemData.departure = null;
            this.formdata.itemData.destination = null;
            this.formdata.itemData.endLngLat = null;
            this.formdata.itemData.startLngLat = null;
          } else {
            this.formdata.isSpecial = '0';
          }

          if (e.name.indexOf('机场接送') > -1 || (e.name.indexOf('火车站') > -1 && this.formdata.city == '厦门市')) {
            this.getzoneinfo();
            this.isShowZoneinfo = true;
          } else {
            this.formdata.itemData.lesserAreas = {};
            this.isShowZoneinfo = false;
          }
          this.formdata.itemData.styleId = e.styleId;
          break;
        case 'cartype':
          this.formdata.carType = e.name;
          this.formdata.itemData.typeId = e.typeId;
          // this.GetQuoteds();
          break;
        case 'useDate':
          if (this.SmartStorage.get('checkCanNewItem').useStartDate && this.checkTime(datatime)) {
            this.$iMessageBox.render({
              btnType: 'single',
              singleTxt: '确定',
              messageTitle: '提示',
              messageTxt: '用车时间超出可用时间范围：' + this.SmartStorage.get('checkCanNewItem').useStartDate + '~' + this.SmartStorage.get('checkCanNewItem').useEndDate + '，不可用车'
            });
            return false;
          }
          this.formdata.dtStart = datatime;
          this.showDataPicker = false;
          break;
        case 'useDate2':
          this.formdata.itemData.tripList[this.tripIndex].dtStart = e.dayTxt;
          this.showDataPicker = false;
          break;
        default:
          break;
      }
    },
    openComponent(type, tripIndex) {
      this.selectList = [];
      console.log(type);
      this.selectOpen = type;
      switch (type) {
        case 'carstyle':
          this.selectList = this.carstyles;
          this.selecttTtle = '请选择用车形式';
          this.formdata.carModus = this.carstyles[0].name;
          this.formdata.itemData.styleId = this.carstyles[0].styleId;
          break;
        case 'cartype':
          this.selectList = this.cartypes;
          this.selecttTtle = '请选择车型';
          break;
        default:
          break;
      }
      this.tripIndex = tripIndex;
      if (type == 'carstyle' || type == 'cartype') {
        this.$refs.selectRef.openPop();
      } else if (type == 'useDate' || type == 'useDate2') {
        this.showDataPicker = true;
        // this.$refs.useDate.openPop();
      }
    },
    getDistance() {
      let that = this;
      this.map = new AMap.Map('containerP', {
        resizeEnable: true
        // mapStyle: "amap://styles/whitesmoke",
      });
      this.planDrivingLine = new AMap.Driving({
        map: that.map,
        showTraffic: true, //实时路况信息
        hideMarkers: true,
        autoFitView: false,
        outlineColor: '#357950'
      });

      // 根据起终点经纬度规划驾车导航路线
      this.planDrivingLine.search(that.formdata.itemData.startLngLat, that.formdata.itemData.endLngLat, function(status, result) {
        let data = (result.routes[0]?.distance * 0.001 || 0).toFixed(2);
        that.formdata.itemData.mileage = data;
        that.changePrice();
        //that.GetQuoteds();
        // result.routes[0] &&
        //   that.openInfo(result.routes[0].distance, result.routes[0].time);
      });
    },
    async GetQuoteds() {
      this.isloding = true;
      let params = {
        City: this.formdata.city,
        Province: this.formdata.province,
        TenantId: this.$cookies.get('tenant_id'),
        departure: this.formdata.itemData.departure,
        destination: this.formdata.itemData.destination,
        Mileage: this.formdata.itemData.mileage
      };
      if (this.formdata.itemData.styleId) {
        params.StyleId = this.formdata.itemData.styleId;
      }
      if (this.formdata.itemData.typeId) {
        params.TypeId = this.formdata.itemData.typeId;
      }
      if (this.formdata.carModus.indexOf('机场接送') > -1 || (this.formdata.carModus.indexOf('火车站') > -1 && this.formdata.city == '厦门市')) {
        params.ZoneId = this.formdata.itemData.lesserAreas.zoneId;
        this.getzoneinfo();
        this.isShowZoneinfo = true;
      }
      console.log(params);
      let _this = this;
      this.services.GetQuoteds(params).then(res => {
        if (res.success) {
          res.content &&
            res.content.forEach((element, index) => {
              if (this.SmartStorage.get('carItem') && element.supplierName == this.SmartStorage.get('carItem').supplierName) {
                element.selected = true;
                _this.choseLevel(element);
              } else {
                element.selected = false;
              }
            });
          let sIndex = res.content && res.content.findIndex(v => v.supplierName.indexOf('测试') > -1);
          sIndex > -1 && res.content && res.content.splice(sIndex, 1);
          this.supplierList = res.content || [];

          this.isloding = res.content ? false : true;
        }
      });
    },
    async openBtnGroup() {
      // if (window.flutter_inappwebview) {
      //   let uploadFileData = {
      //     hideCamera: false,
      //     hideGallery: false,
      //     hidePdfAndOfd: true
      //   };
      //   this.iJsBridge.uploadFile(uploadFileData, this.mappingAsync);
      //   return;
      // }
      this.$refs.btnGroup.showPopup();
    },
    //拍照
    graph() {
      console.log('graph');

      let _this = this;
      uploadInstance.cameraFile({
        callback: data => {
          uploadInstance.addAsync({
            fileList: data,
            ossKey: '',
            bucketAlias: 'SmartEvent',
            callback: result => {
              _this.mappingAsync(result);
            }
          });
        }
      });
    },
    //相册选取
    album() {
      let _this = this;
      uploadInstance.localUpload({
        callback: data => {
          uploadInstance.addAsync({
            fileList: data,
            ossKey: '',
            bucketAlias: 'SmartEvent',
            callback: result => {
              _this.mappingAsync(result);
            }
          });
        }
      });
    },
    mappingAsync(result) {
      console.log('result===', result);
      let Itemid;
      if (this.editFormdata) {
        Itemid = this.formdata.itemId;
      } else {
        Itemid = this.ItemId;
      }
      let mappingData = {
        catalog: '跨城用车',
        itemId: Itemid,
        processId: this.formdata.proposalId,
        relatedType: 'tenant_crossCity_approve'
      };
      uploadInstance.mappingAsync({
        fileKeys: [result[0].content.fileKey],
        mappingData: mappingData,
        callback: () => {
          this.getInvoiceFiles(Itemid);
        }
      });
    },
    getInvoiceFiles(Itemid) {
      uploadInstance.queryFile({
        filterData: {
          filterData: {
            catalog: '跨城用车',
            itemId: Itemid,
            processId: this.formdata.proposalId,
            relatedType: 'tenant_crossCity_approve'
          }
        },
        callback: data => {
          this.approveFilesList = data.content;
        }
      });
    },
    deleteInvoice(list, type) {
      this.list = list;
      Dialog.confirm({
        title: '提示',
        message: '确定删除图片？'
      }).then(() => {
        this.remove();
      });
    },
    remove() {
      let Itemid;
      if (this.editFormdata) {
        Itemid = this.formdata.itemId;
      } else {
        Itemid = this.ItemId;
      }
      uploadInstance.deleteAsync({
        fileItem: {
          fileKey: this.list.fileKey
        },
        bucketAlias: 'SmartEvent',
        callback: () => {
          this.getInvoiceFiles(Itemid);
        }
      });
    },
    //随机itemId
    generateUUID() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (Math.random() * 16) | 0,
          v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
    },
    showPhone() {
      this.$refs.chosePhonePerson.showPopup({
        name: this.formdata.itemData.passenger.name,
        phoneNumber: this.formdata.itemData.passenger_phone
      });
    },
    chosePPerson(data) {
      this.formdata.itemData.passenger.name = data.name;
      if (data.phoneNumber) {
        data.phoneNumber = data.phoneNumber.toString().replace(/-/g, '');
        this.formdata.itemData.passenger_phone = data.phoneNumber.toString().replace(/\s*/g, '');
      }
      this.$forceUpdate();
    }
  }
};
</script>

<style scoped lang="scss">
@import '@/assets/tenant/editOrder.scss';
.imgContainer div {
  float: left;
  position: relative;
  margin: 0 0.2rem 0.2rem 0;
}
.uploadImg {
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 0.04rem;
  background: #f8f8f8;
  display: flex;
  justify-content: center;
  align-items: center;
}

.uploadImg .icon {
  width: 0.4rem !important;
  height: 0.4rem !important;
}
.imgContainer div img {
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 0.04rem;
}
.paymentDelete {
  position: absolute;
  right: -0.1rem;
  top: -0.05rem;
  width: 0.25rem !important;
  height: 0.25rem !important;
}
.hcpBox {
  // width: 30%;
  border-radius: 0.04rem;
  background-color: var(--themeColor);
  color: #fff;
  padding: 0.06rem;
  z-index: 999;
  margin-right: 0.25rem;

  .icon {
    margin-right: 0.02rem;
  }
}
.contact_box .passengerBox {
  overflow: hidden;
}
.contact_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/deep/.MInputContent .MInput input {
  width: 100% !important;
}
</style>
