<template>
  <mt-popup v-model="isShowPopup" position="bottom" class="popup_box">
    <div class="supplierDetail">
      <div class="back-btn title_bg" @click="supplierDetailback()" 
      >
        <div class="back-btn-icon">
          <svg class="icon" aria-hidden="true" v-if="!device">
            <use xlink:href="#icon-fanhui" />
          </svg>
          <div v-if="device">
              返回
          </div>
        </div>
        <div class=" f17">{{itemDetail.name}}</div>
        <div class="back-btn-icon"></div>
      </div>
      <div class="contentDetail">
        <p class="supplierInfo">{{itemDetail.descr}}</p>
        <div class="inforBox">
          <div class="contancBox">
            <p class="mb5">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icondianhua-" />
              </svg>
              联系电话
            </p>
            <a
              @click.stop="ihtml.telCall(contact.mobile)"
              v-for="(contact,conIndex) in itemDetail.contacts"
              :key="conIndex" class="bold"
            >{{contact.name}}({{contact.mobile}})</a>
          </div>
          <span class="contancIcon">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#iconhoutui" />
            </svg>
          </span>
        </div>
        <div class="inforBox info_bg">
          <div class="contancBox">
            <p class="mb5">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icondizhi2-copy" />
              </svg>
              商家地址
            </p>
            <a>{{itemDetail.address}}</a>
          </div>
        </div>
       
        <div class="carTypeMain">
          <div class="carTypeBox">
            <ul>
              <li v-for="(carType,carTypeIndex) in itemDetail.cars" :key="carTypeIndex">
                <img src="../../../assets/images/car2.png" v-if="!carType.uri" />
                <img :src="carType.uri" v-else />
                <div class="typeprice">
                  <div  class="typeprice_top">
                    <p>{{carType.name}}</p>
                    <div class="typeprice_bottom">
                        <div>{{carType.carStyles[0].name}}</div>
                        <div class="priceMain">参考价￥<span class="bold">{{carType.carStyles[0].price}}</span></div>
                    </div>
                  </div>
                  
                </div>
              </li>
            </ul>
          </div>
        </div>
        <a v-if="!justView" class="saveBtn" @click="supplierDetailback(supplierData)">确定</a>
      </div>
    </div>
  </mt-popup>
</template>
<script>
import { device } from '@/views/utils/common';
export default {
   props: [
    "choseLevel",
    "justView"
  ],
  data() {
    return {
      supplierData:{},
      itemDetail: {},
      isShowPopup: false,

    };
  },
  components: {},
  created() {
   this.device=device();
  //  this.device=true

  },
  watch: {
    isShowPopup() {
      if (this.isShowPopup) {
        this.setRouteListeners("supplierDetailback"); //保存当前路由返回事件记录
        this.$root.$eventHub.$on("supplierDetailback", (data) => {
          this.supplierDetailback();
          this.$root.$eventHub.$off("supplierDetailback");
        });
      } else {
        this.removeRouteListeners("supplierDetailback"); //返回删除记录
      }
    },
  },
  methods: {
    supplierDetailback(data) {
      if(data){
        data.selected=true;
        !this.justView&&this.choseLevel(data);
      }
      this.isShowPopup = false;
    },
    showPopup(data,params) {
      this.supplierData=data;
      this.isShowPopup = true;
      this.querySupplier(params);
    },
    async querySupplier(data) {
      let params = {
        supplierId: data.supplierId || "27e1655e-09a1-11e9-9e59-0242ac142204",
        city: data.city || "上海市",
      };
      this.services.getdetailCity(params).then((res) => {
        if (res.success) {
          this.itemDetail = res.content;
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/tenant/supplierDetail.scss";
</style>