<template>
  <div class="carLevelsOne">
    <p class="levelTitle">供应商</p>
    <!-- <p class="tips" v-if="!['roche','msd'].includes($cookies.get('tenant'))">
      如需提名车队
      <span>天津市宇轩汽车租赁有限公司</span>提供服务,请选择
      <span>易来回</span>
      车队
    </p> -->
    <div class="typeLevelList carTypeBox" v-if="levelList.length>0">
      <div
        class="levelmianBox"
        v-for="(level,index) in levelList"
        :key="index"
        @click="choseLevel(level)"
      >
        <div :class="['levelmian', level.selected?'activeLevel':'']">
          <svg class="backicon" aria-hidden="true">
            <use xlink:href="#icon-chengshicheduigongyingshang" />
          </svg>
          <div class="level-title" @click.stop="goDetail(level)">
           
            <i>
              <img :src="level.supplierLog" v-if="level.supplierLog" />
              <img v-else src="../../../assets/images/car.png" />
            </i>
            <span>{{level.supplierName}}</span>
          </div>
          <template v-if="formdata.isSpecial!='1'">
            <p class="price">单价:</p>
            <span class="priceSpan">{{level.price}}*{{formdata.qty}}</span>
            <p class="price">总价:</p>
            <span class="priceSpan">{{level.price*formdata.qty}}</span>
          </template>
          <p v-else class="price">
            具体价格以
            <br />供应商报价为准
          </p>
          <!-- <i class="priceNum">{{level.price||0|currencyFormatter}}</i> -->
          <img class="carImg" :src="level.carTypeLog" v-if="level.carTypeLog" />
          <img class="carImg" v-else src="../../../assets/images/didicar2.png" />
          <p class="typeTxt">{{level.require_level_txt}}</p>
        </div>
        <div class="boxShox" v-if="level.selected"></div>
      </div>
    </div>
    <p v-else class="noinfor">
      <template v-if="formdata.city&&formdata.carModus&&formdata.carType">暂无符合要求的报价
        <br />
        <img v-if="isloding" src="../../../assets/images/Loading.gif" />
      </template>
      <template v-else>请填写星号必填项，以获取车队报价</template>
    </p>
  </div>
</template>

<script>
export default {
  props: [
    "levelList",
    "isloding",
    "goDetail",
    "formdata"
  ],
  data() {
    return {
      initialSwipe: 0,
    };
  },
  components: {},
  mounted() {
    this.levelList &&
      this.levelList.forEach((element) => {
        if (element.selected) {
          // this.choseLevel(element)
          this.isloding = false;
        }
      });
  },
  methods: {
    choseLevel(level) {
      this.levelList.forEach((element) => {
        element.selected = false;
      });
      level.selected = true;
      this.isloding = false;

      this.$forceUpdate();
      this.choseLevelType = level;
      this.$emit('choseLevel', level);
    },
  },
};
</script>

<style scoped="scoped" lang="scss">
@import "@/assets/tenant/carLeve.scss";
</style>